import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueMobileDetection from 'vue-mobile-detection'
// video.js
import 'video.js/core'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import wb from './registerServiceWorker'

import {NavigationRoute, registerRoute} from 'workbox-routing';
import {NetworkOnly} from 'workbox-strategies';

import * as navigationPreload from 'workbox-navigation-preload';

const CACHE_NAME = 'offline-html';
// This assumes /offline.html is a URL for your self-contained
// (no external images or styles) offline page.
const FALLBACK_HTML_URL = '/offline.html';

// Populate the cache with the offline HTML page when the
// service worker is installed.
if(wb !== null)
{
  wb.addEventListener('install', async (event) => {
    event.waitUntil(
        caches.open(CACHE_NAME)
            .then((cache) => cache.add(FALLBACK_HTML_URL))
    );
  });

  navigationPreload.enable();

  const networkOnly = new NetworkOnly();
  const navigationHandler = async (params) => {
    try {
      // Attempt a network request.
      return await networkOnly.handle(params);
    } catch (error) {
      // If it fails, return the cached HTML.
      return caches.match(FALLBACK_HTML_URL, {
        cacheName: CACHE_NAME,
      });
    }
  };

// Register this strategy to handle all navigations.
  registerRoute(
      new NavigationRoute(navigationHandler)
  );
}

Vue.use(VueMobileDetection)

Vue.prototype.$workbox = wb

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
