import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/shop/eCommerceStoreModule';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import auth from './app/auth';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiDomain: '',
    adminDomain: process.env.VUE_APP_DOMAIN_ADMIN,
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    auth,
  },
  strict: process.env.DEV,
})
